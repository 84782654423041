import React from "react"
 
import InlineLogo from "../components/inlinelogo"


const TahirPage = () => (
    <div css={{
      maxWidth: '80%',
      margin: '0 auto',
      }}
    >
        <div css={{ margin: `20px 10px`, maxWidth: `250px`}}>
          <InlineLogo />
        </div>
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdS6QBDU2GRLBZxA2NLQ9I19quwlUlaoiGwuOxjg94JIdTINw/viewform?embedded=true" width="640" height="1269" frameborder="0" marginheight="0" marginwidth="0">Loading...</iframe>
    </div>
)

export default TahirPage
